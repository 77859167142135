/* Box sizing rules */

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */

ul[role='list'],
ol[role='list'] {
	list-style: none;
}

/* Set core root defaults */

html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */

body {
	line-height: 1.5;
	min-height: 100vh;
	text-rendering: optimizespeed;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img,
picture {
	display: block;
	max-width: 100%;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {

	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: .01ms !important;
		animation-iteration-count: 1 !important;
		scroll-behavior: auto !important;
		transition-duration: .01ms !important;
	}
}
