@import 'reset';
@import 'layout';

/* eb-garamond-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/eb-garamond-v26-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* eb-garamond-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 700;
	src: url('/assets/fonts/eb-garamond-v26-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* josefin-sans-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Josefin Sans';
	font-style: normal;
	font-weight: 700;
	src: url('/assets/fonts/josefin-sans-v26-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

:root {
	--color-black: 0 0 0;
	--color-white: 255 255 255;
	--font-default: 'EB Garamond', serif;
	--font-heading: 'Josefin Sans', sans-serif;
}

body {
	font-family: var(--font-default);
	font-size: 1.25rem;
	scroll-padding-top: 120px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
	font-family: var(--font-heading);
}
