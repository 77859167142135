/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  line-height: 1.5;
  min-height: 100vh;
  text-rendering: optimizespeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}
.content-wrapper {
  display: grid;
  grid-template-areas: "pre navigation post" "pre header post" "pre content post";
  grid-template-columns: 1fr min(90vw, 1220px) 1fr;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .navigation {
    background-color: rgb(var(--color-white)/100%);
    bottom: 0;
    font-size: 14px;
    height: 70px;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 100;
  }
  .navigation > ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .navigation > ul > li {
    flex: 0 0 25%;
    padding: 10px 0;
  }
  .navigation > ul > li:not(:last-child) {
    border-right: 1px dashed rgb(var(--color-black)/40%);
  }
  .navigation > ul > li a {
    align-items: center;
    color: rgb(var(--color-black)/100%);
    display: grid;
    grid-template-areas: "icon" "title";
    justify-content: center;
    text-decoration: none;
  }
  .navigation__description {
    display: none;
  }
  .navigation__icon {
    display: flex;
    height: 35px;
    justify-content: center;
  }
  .navigation__icon img {
    max-height: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .navigation {
    font-size: 1rem;
    grid-area: navigation;
    padding-right: 2px;
    position: sticky;
    top: 0;
    z-index: 30;
  }
  .navigation > ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .navigation > ul > li {
    background-image: linear-gradient(to bottom, rgb(var(--color-white)/100%), rgb(var(--color-white)/100%) 75%, rgb(var(--color-white)/0%));
    margin: 0 0 0 1px;
    padding: 0 1rem;
    transform: translateX(1px);
  }
  .navigation > ul > li:first-child {
    grid-column-start: 3;
  }
  .navigation a {
    color: rgb(var(--color-black));
    display: block;
    height: 100%;
    padding: 1rem;
    text-decoration: none;
  }
  .navigation__title {
    font-family: var(--font-heading);
  }
  .navigation__icon {
    display: none;
  }
}
.header {
  align-items: center;
  display: grid;
  grid-area: header/pre/header/post;
  grid-template-areas: "pre content post";
  grid-template-columns: 1fr min(90vw, 1220px) 1fr;
  height: 40vh;
  position: relative;
  z-index: 10;
}
.header .title {
  grid-area: content;
  justify-self: flex-end;
  padding: 0 1rem;
  text-align: right;
}
.header figure {
  aspect-ratio: 1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 50vw;
}
.header figure .header-image__slash {
  grid-column: 1/7;
  grid-row: 1/5;
  height: 100%;
  position: relative;
  transform: translateX(calc(-28% - 20px)) translateY(-1vh);
  z-index: 2;
}
.header figure .header-image__bracket {
  grid-column: 1/5;
  grid-row: 1/4;
  transform: translateX(-1vw);
}

.content {
  grid-area: content/pre/content/post;
  position: relative;
  z-index: 10;
}
.content a:not(.link--no-style) {
  background-clip: text;
  background-image: linear-gradient(60deg, #dd4d4d, #ff8100);
  -webkit-text-fill-color: transparent;
}
.content a:not(.link--no-style):hover {
  background-image: linear-gradient(60deg, #65dd4d, #3660bb);
}
.content a.link--no-style {
  color: inherit;
}
.content a.link--no-style:hover {
  text-decoration: none;
}
.content ul {
  line-height: 2;
}

.band {
  align-items: center;
  display: grid;
  grid-template-areas: "pre left right post";
  grid-template-columns: 1fr repeat(2, min(45vw, 610px)) 1fr;
}
.band .band__media {
  height: 100%;
}
.band .band__media img {
  height: 100%;
  object-fit: cover;
}
.band .band__media:first-child {
  grid-area: pre/pre/left/left;
}
.band .band__media:last-child {
  grid-area: right/right/post/post;
}
.band__content {
  padding: 1rem;
}
.band__content:first-child {
  grid-area: left;
}
.band__content:last-child {
  grid-area: right;
}
.band__content:first-child:last-child {
  grid-area: left/left/right/right;
}
.band--media-gradient .band__media {
  background-image: linear-gradient(60deg, #54ad35, #c9da17);
  background-repeat: no-repeat;
  background-size: 100% 0;
  transition: all 200ms ease;
}
.band--border::before {
  background-image: linear-gradient(60deg, #54ad35, #c9da17);
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  grid-area: pre/pre/post/post;
  height: 4px;
  margin: 0 auto;
  transition: all 200ms ease;
  width: 100%;
}
.band--footer {
  background-color: rgb(var(--color-black)/10%);
  font-size: 80%;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .band {
    grid-template-areas: "top" "bottom";
    grid-template-columns: 1fr;
  }
  .band .band__content {
    grid-area: bottom;
  }
  .band .band__media {
    grid-area: top !important;
  }
  .band .band__media img {
    height: auto;
  }
}
.background-grid {
  border: 1px solid rgb(var(--color-black)/20%);
  border-width: 0 1px;
  display: grid;
  grid-area: navigation/navigation/content/content;
  grid-template-columns: repeat(6, 1fr);
}
.background-grid div {
  background-image: linear-gradient(60deg, #54ad35, #c9da17);
  background-repeat: no-repeat;
  background-size: 100% 0;
  transition: all 200ms ease;
}
.background-grid > *:not(:first-child) {
  border-left: 1px dashed rgb(var(--color-black)/10%);
}

@media screen and (min-width: 1024px) {
  .content-wrapper:has(.navigation li:nth-child(1) a:hover) .background-grid div:nth-child(3) {
    background-size: 100% 100%;
  }
  .content-wrapper:has(.navigation li:nth-child(2) a:hover) .background-grid div:nth-child(4) {
    background-size: 100% 100%;
  }
  .content-wrapper:has(.navigation li:nth-child(3) a:hover) .background-grid div:nth-child(5) {
    background-size: 100% 100%;
  }
  .content-wrapper:has(.navigation li:nth-child(4) a:hover) .background-grid div:nth-child(6) {
    background-size: 100% 100%;
  }
  .content-wrapper:has(.navigation li:nth-child(5) a:hover) .background-grid div:nth-child(7) {
    background-size: 100% 100%;
  }
  .content-wrapper:has(.navigation li:nth-child(6) a:hover) .background-grid div:nth-child(8) {
    background-size: 100% 100%;
  }
}
h1 {
  background-clip: text;
  background-image: linear-gradient(60deg, #dd4d4d, #ff8100);
  font-size: 4.5rem;
  -webkit-text-fill-color: transparent;
}

h2:not(.pre-title) {
  color: rgb(var(--color-black));
  font-size: 3.5rem;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 3.5rem;
  }
  h2:not(.pre-title) {
    font-size: 2.5rem;
  }
}
h2.pre-title {
  background-clip: text;
  background-image: linear-gradient(60deg, #65dd4d, #3660bb);
  font-size: 3.5rem;
  -webkit-text-fill-color: transparent;
}

p {
  margin-bottom: 2rem;
}

dl {
  font-size: 80%;
}
dl dt:not(:first-child) {
  margin-top: 1rem;
}
dl dd {
  color: rgb(var(--color-black)/40%);
  font-style: italic;
}

/* eb-garamond-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/eb-garamond-v26-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* eb-garamond-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/eb-garamond-v26-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* josefin-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/josefin-sans-v26-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
:root {
  --color-black: 0 0 0;
  --color-white: 255 255 255;
  --font-default: "EB Garamond", serif;
  --font-heading: "Josefin Sans", sans-serif;
}

body {
  font-family: var(--font-default);
  font-size: 1.25rem;
  scroll-padding-top: 120px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: var(--font-heading);
}