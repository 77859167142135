.content-wrapper {
	display: grid;
	grid-template-areas: 'pre navigation post' 'pre header post' 'pre content post';
	grid-template-columns: 1fr min(90vw, 1220px) 1fr;
	margin: 0 auto;
}

@media screen and (max-width: 1024px) {

	.navigation {
		background-color: rgb(var(--color-white) / 100%);
		bottom: 0;
		font-size: 14px;
		height: 70px;
		left: 0;
		position: fixed;
		right: 0;
		z-index: 100;

		> ul {
			display: flex;
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;

			> li {
				flex: 0 0 25%;
				padding: 10px 0;

				&:not(:last-child) {
					border-right: 1px dashed rgb(var(--color-black) / 40%);
				}

				a {
					align-items: center;
					color: rgb(var(--color-black) / 100%);
					display: grid;
					grid-template-areas: 'icon' 'title';
					justify-content: center;
					text-decoration: none;
				}
			}
		}

		&__description {
			display: none;
		}

		&__icon {
			display: flex;
			height: 35px;
			justify-content: center;

			img {
				max-height: 100%;
				max-width: 100%;
			}
		}
	}
}

@media screen and (min-width: 1024px) {

	.navigation {
		font-size: 1rem;
		grid-area: navigation;
		padding-right: 2px;
		position: sticky;
		top: 0;
		z-index: 30;

		> ul {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			justify-content: flex-end;
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				background-image: linear-gradient(to bottom, rgb(var(--color-white) / 100%), rgb(var(--color-white) / 100%) 75%, rgb(var(--color-white) / 0%));

				// border-left: 1px dashed rgb(var(--color-black) / 10%);
				margin: 0 0 0 1px;
				padding: 0 1rem;
				transform: translateX(1px);

				&:first-child {
					grid-column-start: 3;
				}
			}
		}

		a {
			color: rgb(var(--color-black));
			display: block;
			height: 100%;
			padding: 1rem;
			text-decoration: none;
		}

		&__title {
			font-family: var(--font-heading);
		}

		&__icon {
			display: none;
		}
	}
}

.header {
	align-items: center;
	display: grid;
	grid-area: header / pre / header / post;
	grid-template-areas: 'pre content post';
	grid-template-columns: 1fr min(90vw, 1220px) 1fr;
	height: 40vh;
	position: relative;
	z-index: 10;

	.title {
		grid-area: content;
		justify-self: flex-end;
		padding: 0 1rem;
		text-align: right;
	}

	figure {
		aspect-ratio: 1;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(4, 1fr);
		left: 0;
		pointer-events: none;
		position: fixed;
		top: 0;
		width: 50vw;

		.header-image {

			&__slash {
				grid-column: 1 / 7;
				grid-row: 1 / 5;
				height: 100%;
				position: relative;
				transform: translateX(calc(-28% - 20px)) translateY(-1vh);
				z-index: 2;
			}

			&__bracket {
				grid-column: 1 / 5;
				grid-row: 1 / 4;
				transform: translateX(-1vw);
			}
		}
	}
}

.content {
	grid-area: content / pre / content / post;
	position: relative;
	z-index: 10;

	a:not(.link--no-style) {
		background-clip: text;
		background-image: linear-gradient(60deg,#dd4d4d,#ff8100);
		-webkit-text-fill-color: transparent;

		&:hover {
			background-image: linear-gradient(60deg,#65dd4d,#3660bb);
		}
	}

	a.link--no-style {
		color: inherit;

		&:hover {
			text-decoration: none;
		}
	}

	ul {
		line-height: 2;
	}

}

.band {
	align-items: center;
	display: grid;
	grid-template-areas: 'pre left right post';
	grid-template-columns: 1fr repeat(2, min(90vw / 2, 1220px / 2)) 1fr;

	.band__media {
		height: 100%;

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	.band__media:first-child {
		grid-area: pre / pre / left / left;
	}

	.band__media:last-child {
		grid-area: right / right / post / post;
	}

	&__content {
		padding: 1rem;
	}

	&__content:first-child {
		grid-area: left;
	}

	&__content:last-child {
		grid-area: right;
	}

	&__content:first-child:last-child {
		grid-area: left / left / right / right;
	}

	&--media-gradient {

		.band__media {
			background-image: linear-gradient(60deg, #54ad35, #c9da17);
			background-repeat: no-repeat;
			background-size: 100% 0;
			transition: all 200ms ease;
		}
	}

	&--border {

		&::before {
			background-image: linear-gradient(60deg, #54ad35, #c9da17);
			background-repeat: no-repeat;
			background-size: 100%;
			content: '';
			display: block;
			grid-area: pre / pre / post / post;
			height: 4px;
			margin: 0 auto;
			transition: all 200ms ease;
			width: 100%;
		}
	}

	&--footer {
		background-color: rgb(var(--color-black) / 10%);
		font-size: 80%;
		text-align: center;
	}
}

@media screen and (max-width: 1024px) {

	.band {
		grid-template-areas: 'top' 'bottom';
		grid-template-columns: 1fr;

		.band__content {
			grid-area: bottom;
		}

		.band__media {
			grid-area: top !important;

			img {
				height: auto;
			}
		}
	}
}

.background-grid {
	border: 1px solid rgb(var(--color-black) / 20%);
	border-width: 0 1px;
	display: grid;
	grid-area: navigation / navigation / content / content;
	grid-template-columns: repeat(6, 1fr);

	div {
		background-image: linear-gradient(60deg, #54ad35, #c9da17);
		background-repeat: no-repeat;
		background-size: 100% 0;
		transition: all 200ms ease;
	}

	> *:not(:first-child) {
		border-left: 1px dashed rgb(var(--color-black) / 10%);
	}
}

// SASS for-loop

@media screen and (min-width: 1024px) {
	@for $i from 1 through 6 {

		.content-wrapper:has(.navigation li:nth-child(#{$i}) a:hover) .background-grid div:nth-child(#{$i + 2}) {
			background-size: 100% 100%;
		}
	}
}

h1 {
	background-clip: text;
	background-image: linear-gradient(60deg,#dd4d4d,#ff8100);
	font-size: 4.5rem;
	-webkit-text-fill-color: transparent;
}

h2:not(.pre-title) {
	color: rgb(var(--color-black));
	font-size: 3.5rem;
	text-align: center;
}

@media screen and (max-width: 1024px) {

	h1 {
		font-size: 3.5rem;
	}

	h2:not(.pre-title) {
		font-size: 2.5rem;
	}
}

h2.pre-title {
	background-clip: text;
	background-image: linear-gradient(60deg,#65dd4d,#3660bb);
	font-size: 3.5rem;
	-webkit-text-fill-color: transparent;
}

p {
	margin-bottom: 2rem;
}

dl {
	font-size: 80%;

	dt {

		&:not(:first-child) {
			margin-top: 1rem;
		}
	}

	dd {
		color: rgb(var(--color-black) / 40%);
		font-style: italic;
	}
}
